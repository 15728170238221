$theme-colors: (
    'primary': #006eab,
    'info': #5bc0de,
    'success': #5ebd5e,
    'warning': #f4b04f,
    'danger': #e66454,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

body {
    margin: 0;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.form-group .form-label {
    font-weight: 700;
}

.cardhousehold {
    margin-bottom: 15px;
}

.cardhouseholddetails {
    font-weight: 700;
}

.labelDOBEstimated {
    font-weight: 500;
}

/* Copied from Pixel Admin */
.btn-labeled .btn-label {
    display: block;
    float: left;
    margin: -6px 12px -8px -13px;
    padding: 11px 12px;
    position: relative;
    background: rgba(0, 0, 0, 0.06);
    line-height: 15px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
}

.btn-label.icon {
    font-size: 14px;
}

.btn-info {
    color: white;
}

.fontsize {
    font-size: small;
}

.link-button {
    min-width: 130px;
}

.react-datepicker-popper {
    z-index: 3;
}

.invalid-language > div {
  border-color: red;
}